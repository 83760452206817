<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="text-muted font-13 mb-0"></p>
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Employee</label>
                          <v-select
                            v-model="employee"
                            label="name"
                            placeholder="None"
                            :options="$store.state.master.employee"
                          >
                            <template
                              #option="{ name, profile_image, username, surname }"
                            >
                              <b-avatar :src="getprofileImage(profile_image)" />
                              <span class="font-weight-bolder">
                                {{ name }} {{ surname }}</span
                              ><span>({{ username }})</span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Activity Type</label>
                          <v-select
                            v-model="activitytype"
                            label="name"
                            placeholder="None"
                            :options="[
                              'Local Area',
                              'Office',
                              'Out of City',
                              'Out of State',
                            ]"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Expense Purpose</label>
                          <v-select
                            v-model="expense_purpose"
                            label="name"
                            placeholder="None"
                            :options="['For Sales', 'Promotion', 'Other']"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            class="form-control"
                            value=""
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                            }"
                            placeholder="Select Date"
                            style="background-color: transparent"
                            @input="getdata($event)"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" class="ml-2" @click="searchData">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <report-table :data="data" :columns="fields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch,
    BAvatar,
  },
  data() {
    return {
      branch: "",
      users: [],
      employee: "",
      employeeOptions: [],
      activitytype: "",
      expense_purpose: "",
      subemployee: "",
      subemployeeoption: [],
      startdate: "",
      enddate: "",
      result: "",
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      data1: [],
      fields: [
        {
          field: "id",
          label: "S.R. No",
        },
        {
          field: "employee",
          label: "Employee Name",
        },
        {
          field: "activitytype",
          label: "Activity Type",
        },
        {
          field: "expense_purpose",
          label: "Expense Purpose",
        },
        {
          field: "date",
          label: "Date",
        },
        {
          field: "place",
          label: "Destination",
        },
        {
          field: "km",
          label: "KM",
        },
        {
          field: "travelfare",
          label: "Travel Fare",
        },
        {
          field: "localconveyance",
          label: "Local Conveyance",
        },
        {
          field: "foodexpense",
          label: "Food Expenses",
        },
        {
          field: "hotelexpense",
          label: "Hotel Expenses",
        },
        {
          field: "otherexpense",
          label: "Other Expenses",
        },
        {
          field: "otherexpensedetails",
          label: "Details of Other Expenses",
        },
        {
          field: "remarks",
          label: "Remark",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("master/getEmployee");
  },
  methods: {
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    async searchData() {
      const data = {
        employee_id: this.employee ? this.employee.id : "",
        startdate: this.startdate,
        enddate: this.enddate,
        activitytype: this.activitytype,
        expense_purpose: this.expense_purpose,
      };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/expensereport`,
      }).then((response) => {
        this.data = response.data.data;
        this.data.map((item) => {
          item.employee = item.employee?.fullname;
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
